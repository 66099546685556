import * as React from "react";

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export function lazyImport(factory) {
  return React.lazy(factory);
}



// Usage
// const { Home } = lazyImport(() => import("./Home"), "Home");

export const routePaths = {
    accueil: "accueil",
    tour: "tours",
    application: "application",
    contact: "contact",
};