import React from 'react';
import { message } from 'antd';

export const Loader = ({isLoading}) => {
  const [messageApi, contextHolder] = message.useMessage();
  messageApi.open({
    isLoading,
    type: 'loading',
    content: 'Chargement...',
  });

  return (
    <>
      {contextHolder}
    </>
  );
}