import React from 'react';
import './Navbar.scss';
import logo from '../../assets/logo.svg';
import { Link, useNavigate } from "react-router-dom";
// import { Link } from "react-scroll";

const Navbar = () => {

  const navigate = useNavigate();
  console.log('navigate',navigate);

  // const [nav, setNav] = useState(false);

  // const handleScroll = () => {
  //   if (window.scrollY >= 50) {
  //     setNav(true);
  //   } else {
  //     setNav(false);
  //   }
  // }

  // window.addEventListener('scroll', handleScroll);

  return (
    <div className={'navbar'}>
      <Link to="accueil" className="logo-part">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <span className='logo-name'>TUKKI</span>
      </Link>
      <ul className="menu-part">
      {/* <li className="item"><Link to="accueil" smooth={true} duration={1000}>Accueil</Link></li> */}
        <li className={`item  ${window.location.pathname === "/accueil" ? "active" : ""}`}><Link to="accueil">Accueil</Link></li>
        <li className={`item  ${window.location.pathname === "/tours" ? "active" : ""}`}><Link to="tours">Tours</Link></li>
        <li className={`item  ${window.location.pathname === "/application" ? "active" : ""}`}><Link to="application">Application</Link></li>
        <li className={`item  ${window.location.pathname === "/contact" ? "active" : ""}`}><Link to="contact">Contact</Link></li>
      </ul>
    </div>
  )
}

export default Navbar