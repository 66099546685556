import Navbar from '../layout/navbar/Navbar';
import { SafeErrorBoundaries } from '../utils/SafeErrorBoundaries/SafeErrorBoundaries';
import { lazyImport, routePaths } from '../utils';
import './App.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

const Home = lazyImport(
  () => import("../views/Home/Home")
);

const Tours = lazyImport(
  () => import("../views/Tours/Tours")
);

const Application = lazyImport(
  () => import("../views/Application/Application")
);

const Contact = lazyImport(
  () => import("../views/Contact/Contact")
);

function App() {
  return (
    <Router>
    <div className="App">
      <div className='navbar-part'>
        <Navbar />
      </div>
      <div className='content-part'>
        <Routes>
          <Route path={routePaths.accueil} element={
            <SafeErrorBoundaries>
              <Home />
            </SafeErrorBoundaries>
          } />
          <Route path={routePaths.tour} element={
            <SafeErrorBoundaries>
              <Tours />
            </SafeErrorBoundaries>
          } />
          <Route path={routePaths.application} element={
            <SafeErrorBoundaries>
              <Application />
            </SafeErrorBoundaries>
          } />
          <Route path={routePaths.contact} element={
            <SafeErrorBoundaries>
              <Contact />
            </SafeErrorBoundaries>
          } />

          <Route path='' element={<Navigate to={routePaths.accueil} />} />

        </Routes>
      </div>
    </div>
    </Router>
  );
}

export default App;
